import React, { useRef, useEffect } from 'react';


const HeaderBar = (props: any) => {
    const tailwindVersion = "v1.4.7 - October 29, 2024";
    let title = "Google Analytics 4 (GA4) - Dimensions and Metrics Cheatsheet";
    if (document.location.pathname.indexOf('ga4-vs-ga4-360') !== -1) {
        title = "GA4 vs GA4 360 - Cheatsheet";
    }
    if (document.location.pathname.indexOf('ga4-events') !== -1) {
        title = "GA4 Events and Parameters Cheatsheet";
    }

    const openMenu = (() => {
        var mobileMenu = document.getElementById("mobile-menu");
        mobileMenu.classList.toggle("hidden");
        document.querySelectorAll("#open-menu > svg")[0].classList.toggle('hidden')
        document.querySelectorAll("#open-menu > svg")[1].classList.toggle('hidden')
    });


    return (
        <div>
            <nav className="bg-green-700" x-data="{open: false}">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">
                        <div className="absolute inset-y-0 left-0 flex items-center md:hidden sm:hidden">
                            <button type="button" className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false" id="open-menu" onClick={openMenu} >
                                <span className="sr-only">Menu</span>
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex  flex-1 justify-center sm:items-stretch sm:justify-start">
                            <a href="https://data.ga4spy.com">
                                <img className="hidden w-auto lg:block" src="https://ga4spy.com/images/datola-luratic.png" alt="GA4 SPY"></img>
                            </a>
                        </div>
                        <div className="flex  items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    <a href="https://datola.es" className="text-gray-50 hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">🍻 Datola Data Community</a>
                                    <a href="https://chrome.google.com/webstore/detail/google-analytics-utils-ua/dmhoedahbmlaipacpamhefgjoiebamjm" className="text-gray-50 hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">🛠️ GA4 UTILS<sup>🆕</sup></a>
                                    <a href="https://chrome.google.com/webstore/detail/ga4-enhanced-debugview-by/hgkkhcgpdigijpbclngegpckcabpjjej" className="text-gray-50 hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">🛠️ GA4 DebugView</a>
                                    <a href="https://tagmanager.google.com/gallery/#/?filter=luratic" className="text-gray-50 hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">🏷️ GTM Templates</a>
                                    <a href="https://ga4spy.com/ga4-comparison-url-generator.html" className="text-gray-50 hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"> 🔗 GA4 Comparison Generator</a>
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            {/* <a href="#changelog" type="button" className="rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">Changelog</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                </svg>
                            </a> */}
                            <div className="relative ml-3"><a className="flex items-center justify-center space-x-2 text-black-400 hover:text-blue-800" href="https://l.ga4spy.com/group"><svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="32px" height="32px">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path>
                            </svg></a>

                            </div>
                            <div className="relative ml-3">
                                <a className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-800" href="https://twitter.com/braiscv">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" width="32px" height="32px"><linearGradient id="_osn9zIN2f6RhTsY8WhY4a" x1="10.341" x2="40.798" y1="8.312" y2="38.769" gradientUnits="userSpaceOnUse"><stop offset="0" /><stop offset="1" /></linearGradient><path fill="currentColor" d="M46.105,11.02c-1.551,0.687-3.219,1.145-4.979,1.362c1.789-1.062,3.166-2.756,3.812-4.758	c-1.674,0.981-3.529,1.702-5.502,2.082C37.86,8.036,35.612,7,33.122,7c-4.783,0-8.661,3.843-8.661,8.582	c0,0.671,0.079,1.324,0.226,1.958c-7.196-0.361-13.579-3.782-17.849-8.974c-0.75,1.269-1.172,2.754-1.172,4.322	c0,2.979,1.525,5.602,3.851,7.147c-1.42-0.043-2.756-0.438-3.926-1.072c0,0.026,0,0.064,0,0.101c0,4.163,2.986,7.63,6.944,8.419	c-0.723,0.198-1.488,0.308-2.276,0.308c-0.559,0-1.104-0.063-1.632-0.158c1.102,3.402,4.299,5.889,8.087,5.963	c-2.964,2.298-6.697,3.674-10.756,3.674c-0.701,0-1.387-0.04-2.065-0.122C7.73,39.577,12.283,41,17.171,41	c15.927,0,24.641-13.079,24.641-24.426c0-0.372-0.012-0.742-0.029-1.108C43.483,14.265,44.948,12.751,46.105,11.02" /></svg>
                                </a>
                            </div>
                            <div className="relative ml-3">
                                <a title="GA4 SPY" href="https://ga4spy.com/" className="px-4 py-2 font-semibold text-center text-black rounded-md bg-primary hover:text-white">GA4SPY</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pt-2 pb-3">
                        <a href="https://ga4spy.com" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🕵️ GA4 SPY</a>
                        <a href="https://ga4spy.com/ga4-comparison-url-generator.html" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🔗 GA4 Comparison URL Generator</a>
                        <a href="https://datola.es" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🍻 Datola Data Community</a>
                        <a href="https://chrome.google.com/webstore/detail/google-analytics-utils-ua/dmhoedahbmlaipacpamhefgjoiebamjm" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🛠️ GA4 UTILS - Chrome Extension</a>
                        <a href="https://chrome.google.com/webstore/detail/ga4-enhanced-debugview-by/hgkkhcgpdigijpbclngegpckcabpjjej" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🛠️ GA4 DebugView - Chrome Extension</a>
                        <a href="https://tagmanager.google.com/gallery/#/?filter=luratic" className="text-gray-50 hover:bg-green-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium">🏷️ GTM Templates</a>
                    </div>
                </div>
            </nav>
            <div className="flex  items-center justify-center justify-center mt-1 border-b-2">
                <div className="mb-1">
                    <div className="flex space-x-4">
                        <a href="/" className="text-green-500 font-bold hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Dimensions & Metrics</a>
                        <a href="/ga4-events-parameters" className="animate-bounce text-green-500 font-bold hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Events & Parameters 🆕</a>
                        <a href="/ga4-vs-ga4-360" className="text-green-500 font-bold hover:bg-green-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium">GA4 vs GA4 360</a>
                    </div>
                </div>
            </div>
            <h1 className="items-center text-lg text-black mt-1 font-bold text-center">{title} <span className="text-xs font-semibold text-gray-50 rounded bg-green-500"><a title="GA4 SPY Changelog" href="http://l.ga4spy.com/ga4-changelog">{tailwindVersion}</a></span></h1>
            {/* <div id="changelog" className="modal bg-white bg-opacity-75">
                <div className="modal-contenido w-2/4">
                    <a href="#" className='text-2xl font-bold text-black'>X</a>
                    <h2 className="font-weight: 900 text-green-900 text-4xl text-center">Changelog</h2>
                    <h3 className="font-weight: 900 text-green-900 text-center mt-2 text-2xl">Oct 17, 2023</h3>
                    <p className="text-green-900 font-weight: 900 text-center mb-4"> All dsata updated. </p>
                    <div className="text-center">
                        <a title="GA4 SPY Changelog" href="http://l.ga4spy.com/ga4-changelog" className="px-4 py-2 font-semibold text-center text-black rounded-md bg-primary hover:text-white">Read More</a>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default HeaderBar;
