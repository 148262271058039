import React from 'react';
import Masonry from 'react-masonry-css';
import Category from '../modules/models/category';
import CategoryComponent from './categoryPage';

const CategoriesPages = (props: any) => {
    let cols = 1;
    if(document.location.pathname.indexOf('/ga4-vs-ga4-360') == -1) {
        cols = 3; 
    }
    return (
        <Masonry
            breakpointCols={{
                default: cols,
                1280: cols,
                1024: cols,
                768: 1,
                640: 1
            }}
            className="flex flex-wrap p-4 mx-auto"
            columnClassName="">
            {
                props.cheatsheet.map((category: Category) => <CategoryComponent key={category.title} category={category} />)
            }
        </Masonry>
    );
}

export default CategoriesPages;
