import React, { useState, MouseEvent } from 'react';
import classNames from "classnames";
import InfoTable from './infoTable';
import { trackShare, trackClickTable } from '../utils/googleAnalytics';

import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { Toast } from "./toast";
import useBus from 'use-bus';

const Subcategory = ({ subcategory }: any) => {
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const hasParameters = urlParams.get('parameter') ? true : false;

    const [isVisible, setIsVisible] = useState(hasParameters);

    const toogleButton = (text: string) => {
        if (text === 'Expand') {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    }
    const toggleCollapse = (type: string) => {
        setIsVisible(!isVisible);
        trackClickTable(type);
    };

    const onClickLink = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.stopPropagation();
    };

    function handleClick(text: string) {
        setToastText(text)
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, 5000)
    }

    useBus(
        'ui/expand',
        () => {
            const valueButton = document.querySelector('#text-button-expand').textContent;
            toogleButton(valueButton);
        }
    );

    return (
        <div id={"type-" + subcategory.type}>
            {showToast && <Toast text={toastText} />}
            <div
                className="flex items-center px-3 py-2 text-gray-700 border-gray-300 cursor-pointer hover:bg-green-500 hover:text-white hover:shadow-lg" 
 
                onClick={() => toggleCollapse(subcategory.title)}
            >
                <h1 className="flex-1 font-semibold text-sm">{subcategory.title}</h1>
                <a
                    className={"px-2 py-1 text-xs font-bold text-gray-600 uppercase rounded-md " + (subcategory.type === "dimension" ? "bg-yellow-100 hover:bg-yellow-300" : "bg-purple-100 hover:bg-purple-300")}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {subcategory.type}
                </a>
                <svg className="h-5 w-5 ml-1 text-grey-500 hover:text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                    onClick={async () => {
                        await copyTextToClipboard("https://data.ga4spy.com/?parameter=" + subcategory.frontendName).then(() =>
                            handleClick("https://data.ga4spy.com/?parameter=" + subcategory.frontendName)
                            );
                        trackShare(subcategory.frontname);
                    }}>  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="6" cy="12" r="3" />  <circle cx="18" cy="6" r="3" />  <circle cx="18" cy="18" r="3" />  <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />  <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" /></svg>
            </div>
            <div className={classNames('bg-green-200 px-4 overflow-hidden break-all; break-all', {
                'h-0': !isVisible
            })}>
                <p className="my-3 leading-tight text-black text-sm"><span id="item-description" dangerouslySetInnerHTML={{ __html: subcategory.description }}/> </p>
                <InfoTable table={subcategory.table} />
            </div>
        </div>
    );
}

export default Subcategory;
