import React, { useState, MouseEvent } from 'react';
import classNames from "classnames";
import InfoTablePages from './infoTablePages';
import InfoTableEvents from './infoTableEvents';

import { trackShare, trackClickTable } from '../utils/googleAnalytics';

import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { Toast } from "./toast";
import useBus from 'use-bus';



const Subcategory = ({ subcategory }: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasParameters = urlParams.get('type') ? true : false;
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState("");

    const [isVisible, setIsVisible] = useState(hasParameters);

    const toogleButton = (text: string) => {
        if (text === 'Expand') {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    }
    const toggleCollapse = (type: string) => {
        setIsVisible(!isVisible);
        trackClickTable(type);
    };

    const onClickLink = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.stopPropagation();
    };

    function handleClick(text: string) {
        setToastText(text)
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, 5000)
    }
    let InfoTable = InfoTablePages;
    if(document.location.pathname.indexOf('/ga4-vs-ga4-360') == -1) {
        InfoTable = InfoTableEvents; 
    }
    useBus(
        'ui/expand',
        () => {
            const valueButton = document.querySelector('#text-button-expand').textContent;
            toogleButton(valueButton);
        }
    );

    return (
        <div>
            {showToast && <Toast text={toastText} />}
            <div
                className="flex items-center px-3 py-2 text-gray-700 border-gray-300 cursor-pointer hover:bg-green-500 hover:text-white hover:shadow-lg"
                onClick={() => toggleCollapse(subcategory.title)}
            >
                <h1 className="flex-1 font-semibold text-sm">{subcategory.title}</h1>
                <a
                    className="px-2 py-1 text-xs font-bold text-white uppercase bg-green-400 rounded-md hover:bg-primary"
                    target="_blank"
                    rel="noopener noreferrer"

                >
                    {subcategory.type}
                </a>
                <svg className="h-5 w-5 ml-1 text-yellow-400 hover:text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                    onClick={async () => {
                        let copyTerm = subcategory.type.toLocaleLowerCase()
                        if(document.location.pathname.indexOf('/ga4-vs-ga4-360') == -1) {
                            copyTerm = subcategory.title.toLocaleLowerCase()
                        }
                        const uri = document.location.hostname + document.location.pathname + "?type=" + encodeURIComponent(copyTerm);
                        await copyTextToClipboard(uri).then(() =>
                            handleClick(document.location.hostname + document.location.pathname + "?type=" + encodeURIComponent(copyTerm))
                        );
                        trackShare(copyTerm);

                    }}>  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="6" cy="12" r="3" />  <circle cx="18" cy="6" r="3" />  <circle cx="18" cy="18" r="3" />  <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />  <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" /></svg>
            </div>
            <div className={classNames('bg-green-200 px-4 overflow-hidden break-all; break-all', {
                'h-0': !isVisible
            })}>
                {/* <div className="flex flex-wrap my-3">
                    <button className="bg-teal-500 dark:bg-teal-700 text-md mr-1 mb-1 rounded-r-md border border-transparent px-1 font-bold text-white focus:border-dotted focus:border-gray-600 focus:outline-none">Web</button>
                    <button className="bg-teal-500 dark:bg-teal-700 text-md mr-1 mb-1 rounded-r-md border border-transparent px-1 font-bold text-white focus:border-dotted focus:border-gray-600 focus:outline-none">Android</button>
                    <button className="bg-teal-500 dark:bg-teal-700 text-md mr-1 mb-1 rounded-r-md border border-transparent px-1 font-bold text-white focus:border-dotted focus:border-gray-600 focus:outline-none">App</button>
                </div> */}
                <p className="my-3 leading-tight text-black text-sm"><span id="item-description" dangerouslySetInnerHTML={{ __html: subcategory.description }} /> </p>
                <InfoTable table={subcategory.table} />
            </div>
        </div>
    );
}

export default Subcategory;
