import React from 'react';
import classNames from "classnames";

const InfoTable = ({ table }: any) => {
    const parseText = (text: string): any => {
        if (text.indexOf('<') > 0) {
            return <div dangerouslySetInnerHTML={{ __html: text }} />
        }

        return text;
    };
    return (
        <table className="w-full mb-4 bg-green-50 rounded">
            <tbody>
                {
                    table.map((tr: string[], index: Number) => {
                        return (
                            <tr key={'tr-' + index}>
                                {
                                    tr.map((td: string, index: Number) => {
                                            return (
                                                <td
                                                    key={'td-' + index} 
                                                    className={classNames('font-bold text-xs p-2 border-b border-gray-300 ', {
                                                        'text-gray-900 font-bold ': index === 0,
                                                        'text-gray-500 ': index === 1,
                                                        'text-green-600 text-xs' : index === 2,
                                                        'text-yellow-600 text-xs': index === 3,
                                                        'text-gray-400 text-xs' : index === 4,
                                                        
                                                    },{'hidden ': Number(index) > 1 && window.screen.width <= 640},
                                                    {'w-1/2': window.screen.width <= 640},
                                                    {'w-1/5 ': window.screen.width >= 640}
                                                    
                                                    )}>{parseText(td)} </td>
                                            );
                                    })
                                }
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}

export default InfoTable;
