import ReactGA from 'react-ga';

ReactGA.initialize('');
let timeoutTrackSearch: any;

export const trackView = (viewName: string) => {
    //ReactGA.pageview(viewName);
}

export const trackSearch = (searchText: string) => {
    clearTimeout(timeoutTrackSearch);

    timeoutTrackSearch = setTimeout(() => {
        ReactGA.event({
            category: "search input",
            action: "search",
            label: searchText,
        });
    }, 500);
}

export const trackShare = (method: string) => {
        ReactGA.event({
            category: "share",
            action: "share",
            label: method,
        });
}

export const trackExpand = (type: string) => {
    type = type || 'expand';
    ReactGA.event({
        category: 'search_bar',
        action: 'click_expand_collapse',
        label: type
    });
}

export const trackClickTable = (type: string) => {
    ReactGA.event({
        category: 'table',
        action: 'click_table',
        label: type
    });
}

export const trackApplyFilter = (type: string) => {
    ReactGA.event({
        category: 'search_bar',
        action: 'apply_filter',
        label: type
    });
}

