import React from 'react';

const Footer = (props: any) => (
    <div className="container px-6 mx-auto mt-16">
        <div className="flex flex-col items-center">
            <div className="py-6 text-center sm:w-2/3">
                <p className="text-sm text-grey-800">
                © {(new Date().getFullYear())} Hacked by <a href="https://luratic.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Luratic Corp</a> 🦑
                </p>
                <p className="text-sm text-grey-800">
                    Powered by <a href="https://datola.es" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Datola Community</a> 🌊🍻
                </p>
                <p className="text-sm text-grey-800">
                    Feito a man dende Galicia con moito amor e sentidiño 💙
                </p>
                <p className="text-sm text-gray-400">
                Cheatsheet Template by<a href="https://github.com/tailwindcomponents" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:underline">Tailwind Components</a></p>
            </div>
        </div>
    </div>
);

export default Footer;
