import React, { useState, useEffect } from 'react';

import Category from '../modules/models/category';

import { trackView, trackSearch, trackApplyFilter } from '../utils/googleAnalytics';

import SearchBar from '../components/searchBar';
import Categories from '../components/categories';
import Footer from '../components/footer';

let timeoutSearch: any;


const Home = (props: any) => {
    const json: Category[] = require('../modules/cheatsheet.json');

    const urlParams = new URLSearchParams(window.location.search);
    const parametersQuery = urlParams.get('parameter');
    const type = urlParams.get('type');
    const category = urlParams.get('category');
    const searchTerm = urlParams.get('search');
    const filterParam = urlParams.get('filter');

    let filterParameter: Category[];
    if (searchTerm) {
        filterParameter = json.map(function (elements) {
            var result = elements.content.filter(function (items) {
                if (JSON.stringify(items).toLocaleUpperCase().indexOf(decodeURIComponent(searchTerm.toLocaleUpperCase())) > -1) {
                    return items
                }
            })
            if (result.length) {
                return {
                    title: elements.title,
                    content: result
                }
            }
        }).filter(Boolean);
    }
    if (type) {
        filterParameter = json.map(function (elements) {
            var result = elements.content.filter(function (items) {
                if (items.type === type) {
                    return items
                }
            })
            if (result.length) {
                return {
                    title: elements.title,
                    content: result
                }
            }
        }).filter(Boolean);
    }
    if (category) {
        filterParameter = json.map(function (elements) {
            const categories = category.split(',');
            for (let i = 0; i < categories.length; i++) {
                if (elements.title.toLocaleLowerCase() === categories[i].toLocaleLowerCase()) {
                    return elements
                }
            }
        }).filter(Boolean);
    }

    if (parametersQuery) {
        const parameters = parametersQuery.split(',');
        filterParameter = json.map(function (elements) {
            var result = elements.content.filter(function (items) {
                for (let i = 0; i < parameters.length; i++) {
                    if (items.frontendName === parameters[i]) {
                        return items
                    }
                }
            })
            if (result.length) {
                return {
                    title: elements.title,
                    content: result
                }
            }
        }).filter(Boolean);
    }

    let result = filterParameter || json;


    const [cheatsheet, setCheatsheet] = useState<Category[]>(result);
    useEffect(() => {
        trackView('/cheatsheet');
    }, []);

    const search2 = (event: any) => {
        const text: string = event.target.value;
        removeParameterURL('search');
        resetCheckBox();
        document.querySelector("#search")['value'] = '';
        const json: Category[] = require('../modules/cheatsheet.json');

        let filterParameter: Category[];
        if (text && text !== 'All Data') {
            const filterText = JSON.parse(text.split(',')[0]).replace(/\s/g, '');
            addParameterURL('filter', filterText);

            filterParameter = json.map(function (elements) {
                let result = elements.content.filter(function (items) {
                    if (JSON.stringify(items).toLocaleUpperCase().indexOf(text.toUpperCase()) > -1) {
                        return items
                    }
                })
                if (result.length) {
                    return {
                        title: elements.title,
                        content: result
                    }
                }
            }).filter(Boolean);
        }
        let result = filterParameter || json;


        setCheatsheet(result);
        trackSearch(text);
    }
    const filter = (event: any) => {
        const type: string = event.target.value;
        const isChecked = event.target.checked;
        document.querySelectorAll("#type-" + type).forEach(function (item) {
            if(isChecked) {
                item["hidden"] = false;
            }
            else {
                item["hidden"] = true;
            }
        })
        trackApplyFilter(event.target.value);
    }

    const resetCheckBox = () => {
        document.querySelector("#dimension-checkbox")["checked"] = true;
        document.querySelector("#metric-checkbox")["checked"] = true;
    }
    const addParameterURL = (key: string, value: string) => {
        const url: any = new URL(window.location.href);
        url.searchParams.set(key, encodeURIComponent(value.toLocaleLowerCase()));
        window.history.pushState(null, '', url.toString());
    }
    const removeParameterURL = (key: string) => {
        const url: any = new URL(window.location.href);
        url.searchParams.delete(key);
        window.history.pushState(null, '', url.toString());
    }

    const search = (event: any) => {
        resetCheckBox();
        const json: Category[] = require('../modules/cheatsheet.json');
        let text: string = event.target.value;

        if (text === '') {
            removeParameterURL('search');
            setCheatsheet(json);
            return;
        }
        document.querySelector("#ga4types")['value'] = document.querySelector("#ga4types")[0].value
        addParameterURL('search', text);

        clearTimeout(timeoutSearch);

        timeoutSearch = setTimeout(() => {
            let filterParameter: Category[];
            removeParameterURL('filter');
            filterParameter = json.map(function (elements) {
                let result = elements.content.filter(function (items) {
                    if (JSON.stringify(items).toLocaleUpperCase().indexOf(text.toUpperCase()) > -1) {
                        return items
                    }
                })
                if (result.length) {
                    return {
                        title: elements.title,
                        content: result
                    }
                }
            }).filter(Boolean);

            let result = filterParameter || json;
            setCheatsheet(result);
            trackSearch(text);
        }, 500);
    };
    return (
        <>
            <SearchBar search={search} search2={search2} filter={filter} />
            <Categories cheatsheet={cheatsheet} />
            <Footer />
        </>
    );
}

export default Home;
